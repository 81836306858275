import { Route, Routes } from 'react-router-dom';
import './App.scss';
import Loader from './components/Spinner/Loader';
import Home from './pages/Home/Home';
import Auth from './pages/auth/Auth';
import Dashboard from './pages/dashboard/Dashboard';
import Profile from './pages/dashboard/Profile';
import StartInterview from './pages/dashboard/StartInterview';
import Feedback from './pages/dashboard/Feedback';
import Technical from './pages/interviewProcess/Technical';
import PrivateRoutes from './routes/PrivateRoutes';
import PrivateRoute1 from './routes/PrivateRoute1';
import TermsOfService from './pages/termsOfService/TermsOfService';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy';
import Blogs from './pages/blogs/Blogs';
import BlogPage from './pages/blogPage/BlogPage';
import AdminBlogs from './pages/admin/AdminBlogs';
import EditBlogPage from './pages/editBlogPage/EditBlogPage';
import Success from './pages/paymentSuccess/Success';
import Subscription from './pages/dashboard/Subscription';
import Affiliate from './pages/affiliate/Affiliate';

function App() {
  return (
    <>
      <Loader />
      <Routes>
        <Route path='/' element= {<Home />}></Route>
        <Route path='/privacyPolicy' element={<PrivacyPolicy />}></Route>
        <Route path='/termsOfService' element={<TermsOfService />}></Route>
        <Route path='/blogs' element={<Blogs />}></Route>
        <Route path='blogs/blog/:blogId' element= {<BlogPage />}></Route>
        <Route path='/' element={<PrivateRoutes><Dashboard /></PrivateRoutes>}>
          <Route path='/profile' element={<Profile />} />
          <Route path='/interview' element={<StartInterview />} />
          <Route path='/feedback' element={<Feedback />} />
          <Route path='/subscription' element={<Subscription />} />
        </Route>
        <Route path='/admin' element={<PrivateRoute1><AdminBlogs /></PrivateRoute1>}></Route>
        <Route path='/admin/editBlog/:blogId' element= {<PrivateRoute1><EditBlogPage /></PrivateRoute1>}></Route>
        <Route path='/admin/newBlog' element= {<PrivateRoute1><EditBlogPage /></PrivateRoute1>}></Route>
        <Route path='/interviewProcess' element={<PrivateRoutes><Technical /></PrivateRoutes>} />
        <Route path='/login' element={<Auth page="login" />} />
        <Route path='/signup' element={<Auth page="signup" />} />
        <Route path='/forgotpassword' element={<Auth page="forgotpassword" />} />
        <Route path='/resetpassword' element={<Auth page="resetpassword" />} />
        <Route path='/paymentSuccess' element={<Success />} />
        <Route path="/affiliate" element={<Affiliate />} />
      </Routes>
    </>
  );
}

export default App;
