import React, { useEffect, useState } from 'react'
import NavBar from '../NavBar/NavBar';
import BlogCard from '../../components/BlogCard';
import './../../scss/Blogs.scss';
import blogImg1 from '../../assests/images/blogImg1.png';
import blogImg2 from '../../assests/images/blogImg2.png';
import blogImg3 from '../../assests/images/blogImg3.png';
import blogPageFirstCardImg from '../../assests/images/blogPageFirstCardImg.png';
import Footer from '../../components/Footer';
import ReactPaginate from 'react-paginate';
import { GrFormPrevious } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from '../../util/api';
import { setLoading } from '../../redux/userSlice';

function Blogs() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(6);
    const [allBlogs, setallBlogs] = useState([]);
    const [latestBlog, setLatestBlog] = useState(null);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;

    const handlePaginate = ({ selected }) => {
        setCurrentPage(selected + 1);
    }

    useEffect(() => {
        window.scrollTo(0, 0);

        dispatch(setLoading(true));
        axios.post('/get_all_blogs').then(res => {
            dispatch(setLoading(false));
            if (res.success) {
                setallBlogs(res.blogs);
                if (res.blogs?.length > 0) {
                    setLatestBlog(res.blogs[res.blogs.length - 1])
                }
            }
        }).catch(error => {
            dispatch(setLoading(false));
            console.log(error);
        })
    }, [])

    return (
        <div className='blogs-wrapper'>
            <NavBar />
            <div className='blogs-header'>
                <h1>Blogs</h1>
            </div>
            <div className='blogs-content'>
                {latestBlog && (
                    <div className='first-card-section'>
                        <div className='card-header'>
                            <img src={`data:image/png;base64,${latestBlog.image}`} alt='img'></img>
                        </div>
                        <div className='card-description-container'>
                            <div className='card-description'>
                                <h4 className='heading'>{latestBlog.title}</h4>
                                <p className='description'>{latestBlog.description}</p>
                            </div>
                            <button onClick={() => navigate(`/blogs/blog/${latestBlog.id}`)}>Read More</button>
                        </div>
                    </div>
                )}
                <div className='cards'>
                    {
                        allBlogs?.slice(indexOfFirstPost, indexOfLastPost).map((data, index) => {
                            return <BlogCard key={index} imgurl={data.image} heading={data.title} description={data.description} blogId={data.id} />
                        })
                    }
                </div>
                <div className='paginate-tabs'>
                    <ReactPaginate
                        onPageChange={handlePaginate}
                        pageCount={Math.ceil(allBlogs?.length / postsPerPage)}
                        previousLabel={<GrFormPrevious />}
                        nextLabel={<GrFormNext />}
                        containerClassName={'pagination'}
                        pageLinkClassName={'page-number'}
                        previousLinkClassName={'page-number'}
                        nextLinkClassName={'page-number'}
                        activeLinkClassName={'active'}
                    />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Blogs