import React, { useState, useEffect, useRef } from 'react'
import '../../scss/Profile.scss';
import { HiMiniUsers } from "react-icons/hi2";
import { IoIosAddCircle } from "react-icons/io";
import sarahImage from '../../assests/images/sarah.png';
import cameraIcon from '../../assests/images/camera-icon.png';
import Select from "react-select";
import { useSelector, useDispatch } from 'react-redux'
import axios from '../../util/api';
import { setLoading, setUser, setUserProfile } from '../../redux/userSlice';
import { useNavigate } from 'react-router-dom';

const genderOption = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Others", label: "Others" }
];

const currentYear = new Date().getFullYear();

const yearOptions = Array.from({ length: 38 }, (_, index) => ({
  value: currentYear - index,
  label: currentYear - index,
}));

const monthOptions = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((month) => ({ label: month, value: month }))

export default function Profile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const userProfile = useSelector(state => state.user.userProfile);
  const [personalInfo, setPersonalInfo] = useState({ firstname: user?.first_name, lastname: user?.last_name, email: user?.email });
  const [selectedGender, setSelectedGender] = useState(null);
  const [primarySkill, setPrimarySkill] = useState(['']);
  const [secondarySkill, setSecondarySkill] = useState(['']);
  const [certifications, setcertifications] = useState(['']);
  const [educations, setEducations] = useState([{ degree: '', year: null, college: '' }]);
  const [works, setWorks] = useState([{ title: '', company: '', start_month: null, start_year: null, end_month: null, end_year: null, roles: '' }]);
  const [editProfile, setEditProfile] = useState(false);
  const [formErrors, setFormErrors] = useState({ firstname: '', primarySkill: '' });
  const [profileInfo, setProfileInfo] = useState(null)
  const [profileImage, setProfileImage] = useState(null);
  const [profileURL, setProfileURL] = useState(null);
  const [profileImageChange, setprofileImageChange] = useState(false);

  const fileInputRef = useRef(null);

  const handleIncrement = (type) => {
    if (editProfile === true) {
      if (type === 'primary' && primarySkill.length < 5) {
        setPrimarySkill([...primarySkill, '']);
      }
      if (type === 'secondary' && secondarySkill.length < 5) {
        setSecondarySkill([...secondarySkill, '']);
      }
      if (type === 'certification' && certifications.length < 5) {
        setcertifications([...certifications, '']);
      }
      if (type === 'education' && educations.length < 3) {
        setEducations([...educations, { degree: '', year: null, college: '' }]);
      }
      if (type === 'work' && works.length < 3) {
        setWorks([...educations, { title: '', company: '', start_month: null, start_year: null, end_month: null, end_year: null, roles: '' }]);
      }
    }
  }

  const handlePrimarySkillChange = (e, index) => {
    const newPrimarySkills = [...primarySkill];
    newPrimarySkills[index] = e.target.value;
    setPrimarySkill(newPrimarySkills);
  }

  const handleSecondarySkillChange = (e, index) => {
    const newSecondarySkills = [...secondarySkill];
    newSecondarySkills[index] = e.target.value;
    setSecondarySkill(newSecondarySkills);
  }

  const handleCertificationChange = (e, index) => {
    const newCertification = [...certifications];
    newCertification[index] = e.target.value;
    setcertifications(newCertification);
  }

  const handleEducationChange = (e, index) => {
    let newEducation = [...educations]
    educations[index][e.target.name] = e.target.value;
    setEducations(newEducation);
  }

  const handleEducationYearChange = (e, index) => {
    let newEducation = [...educations]
    educations[index].year = e;
    setEducations(newEducation);
  }

  const handleWorkChange = (e, index) => {
    const newWork = [...works];
    newWork[index][e.target.name] = e.target.value;
    setWorks(newWork)
  }

  const handleWorkDateChange = (e, value, index) => {
    const newWork = [...works];
    newWork[index][value.name] = e;
    setWorks(newWork)
  }

  const validateProfileForm = () => {
    var errors = { firstname: '', primarySkill: '' };
    let { firstname } = personalInfo;
    var isError = false;
    if (firstname.length < 1) {
      errors.firstname = 'Enter Valid First Name'
      isError = true;
    } else if (primarySkill[0] < 3) {
      errors.primarySkill = 'Enter primary skill'
      isError = true;
    }

    setFormErrors(errors);
    return isError;
  }

  const handleProfileSubmit = (e) => {
    e.preventDefault();
    if (user?.user_id && !validateProfileForm()) {
      let degree = []
      let year_of_completion = []
      let college_name = [];
      educations.forEach(element => {
        degree.push(element.degree);
        year_of_completion.push(element.year?.value || '');
        college_name.push(element.college);
      });

      let job_title = []
      let company_name = []
      let starting_month = []
      let starting_year = []
      let ending_month = []
      let ending_year = []
      let roles_and_responsibilities = []

      works.forEach(element => {
        job_title.push(element.title);
        company_name.push(element.company);
        starting_month.push(element.start_month?.value || '');
        starting_year.push(element.start_year?.value || '');
        ending_month.push(element.end_month?.value || '');
        ending_year.push(element.end_year?.value || '');
        roles_and_responsibilities.push(element.roles);
      });
      const profileDetail = {
        user_id: user?.user_id,
        First_Name: personalInfo.firstname,
        Last_Name: personalInfo.lastname,
        email: user?.email,
        gender: selectedGender?.value || '',
        primary_skills: JSON.stringify(primarySkill),
        secondary_skills: JSON.stringify(secondarySkill),
        certifications: JSON.stringify(certifications),
        degree: JSON.stringify(degree),
        year_of_completion: JSON.stringify(year_of_completion),
        college_name: JSON.stringify(college_name),
        job_title: JSON.stringify(job_title),
        company_name: JSON.stringify(company_name),
        starting_month: JSON.stringify(starting_month),
        starting_year: JSON.stringify(starting_year),
        ending_month: JSON.stringify(ending_month),
        ending_year: JSON.stringify(ending_year),
        roles_and_responsibilities: JSON.stringify(roles_and_responsibilities),
        profile_picture: profileImage || null
      }

      dispatch(setLoading(true))
      axios.post('/create_profile', profileDetail).then((res) => {
        setprofileImageChange(false);
        if (res.success) {
          setprofileImageChange(false);
          setEditProfile(false);
          setProfileImage(res.profile_picture_url);
          setProfileURL(res.profile_picture_url || '');
          localStorage.setItem('user', JSON.stringify({ ...user, profileUrl: res.profile_picture_url, first_name: personalInfo.firstname, last_name: personalInfo.lastname }))
          dispatch(setUser({ ...user, profileUrl: res.profile_picture_url, first_name: personalInfo.firstname, last_name: personalInfo.lastname }))
          dispatch(setUserProfile({...userProfile, profile_picture: res.profile_picture_url}))
        }
        dispatch(setLoading(false))
      }).catch(error => {
        console.log(error);
        dispatch(setLoading(false))
      })
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith('image/')) {
      const imageUrl = URL.createObjectURL(file);
      setProfileURL(imageUrl);
      setprofileImageChange(true);
      setProfileImage(file);
    }
  };

  const handleImageSubmit = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  // useEffect(() => {
  //   if (user?.user_id) {
  //     axios.post(`/get_user_profile/${user?.user_id}`).then((res) => {
  //       console.log(res);
  //       if (res.success) {
  //         setProfileInfo(res.profile);
  //         setSelectedGender({ label: res.profile?.gender, value: res.profile?.gender })
  //         setPrimarySkill(res.profile?.primary_skills || [])
  //         setSecondarySkill(res.profile?.secondary_skills || [])
  //         setcertifications(res.profile?.certifications || [])
  //         setProfileImage(res.profile?.profile_picture || null)
  //         setProfileURL(userProfile?.profile_picture || null)
  //         localStorage.setItem('user', JSON.stringify({...user, profileUrl: res.profile?.profile_picture}))
  //         dispatch(setUser({...user, profileUrl: userProfile?.profile_picture}))

  //         let educationObj = [];
  //         res.profile?.year_of_completion.forEach((element, index) => {
  //           educationObj.push({
  //             degree: res.profile?.degree[index] || '',
  //             year: element ? { label: element, value: element } : null,
  //             college: res.profile?.college_name[index] || ''
  //           })
  //         });

  //         setEducations(educationObj);

  //         let workObj = [];
  //         res.profile?.job_title.forEach((title, index) => {
  //           workObj.push({
  //             title: title,
  //             company: res.profile?.company_name[index] || '',
  //             start_month: res.profile?.starting_month[index] ? { label: res.profile?.starting_month[index], value: res.profile?.starting_month[index] } : null,
  //             start_year: res.profile?.starting_year[index] ? { label: res.profile?.starting_year[index], value: res.profile?.starting_year[index] } : null,
  //             end_month: res.profile?.ending_month[index] ? { label: res.profile?.ending_month[index], value: res.profile?.ending_month[index] } : null,
  //             end_year: res.profile?.ending_year[index] ? { label: res.profile?.ending_year[index], value: res.profile?.ending_year[index] } : null,
  //             roles: res.profile?.roles_and_responsibilities[index] || ''
  //           })
  //         });
  //         setWorks(workObj)
  //       }
  //     }).catch(error => {
  //       console.log(error);
  //     })
  //   }
  // }, [])

  useEffect(() => {
    if (userProfile) {
      setProfileInfo(userProfile);
      setSelectedGender({ label: userProfile?.gender, value: userProfile?.gender })
      setPrimarySkill(userProfile?.primary_skills ? (userProfile?.primary_skills.length === 0 ? [''] : userProfile?.primary_skills) : [''])
      setSecondarySkill(userProfile?.secondary_skills ? (userProfile?.secondary_skills.length === 0 ? [''] : userProfile?.secondary_skills) : [''])
      setcertifications(userProfile?.certifications ? (userProfile?.certifications.length === 0 ? [''] : userProfile?.certifications) : [''])
      setProfileImage(userProfile?.profile_picture || null)
      setProfileURL(userProfile?.profile_picture || null)

      let educationObj = [];
      userProfile?.year_of_completion?.forEach((element, index) => {
        educationObj.push({
          degree: userProfile?.degree[index] || '',
          year: element ? { label: element, value: element } : null,
          college: userProfile?.college_name[index] || ''
        })
      });
      if (educationObj.length <= 0) {
        educationObj = [{ degree: '', year: null, college: '' }];
      }

      setEducations(educationObj);

      let workObj = [];
      userProfile?.job_title?.forEach((title, index) => {
        workObj.push({
          title: title,
          company: userProfile?.company_name[index] || '',
          start_month: userProfile?.starting_month[index] ? { label: userProfile?.starting_month[index], value: userProfile?.starting_month[index] } : null,
          start_year: userProfile?.starting_year[index] ? { label: userProfile?.starting_year[index], value: userProfile?.starting_year[index] } : null,
          end_month: userProfile?.ending_month[index] ? { label: userProfile?.ending_month[index], value: userProfile?.ending_month[index] } : null,
          end_year: userProfile?.ending_year[index] ? { label: userProfile?.ending_year[index], value: userProfile?.ending_year[index] } : null,
          roles: userProfile?.roles_and_responsibilities[index] || ''
        })
      });
      if (workObj.length <= 0) {
        workObj = [{ title: '', company: '', start_month: null, start_year: null, end_month: null, end_year: null, roles: '' }]
      }
      setWorks(workObj)
    }
  }, [userProfile])

  return (
    <>
      <div className="profile-section">
        <div className="profile-heading"><HiMiniUsers /> Profile</div>
        <div className="profile-content">
          <div className="profile-image">
            <div className='profile-left'>
              <img src={profileImageChange ? profileURL : profileURL ? `data:image/png;base64,${profileURL}` : sarahImage} alt="profile" />
              <img className='camera-icon' src={cameraIcon} onClick={handleImageSubmit} alt="camera" />
              <input
                type='file'
                id='profile_pic'
                accept='image/*'
                ref={fileInputRef}
                onChange={handleFileChange}
                hidden
                disabled={!editProfile}
              />
              <div className="profile-name">{user?.first_name} {user?.last_name}</div>
            </div>
            <div className='profile-right d-flex flex-column justify-content-center'>
              <h4>Take control of your job search! </h4>
              <p>Set up your profile now and gain an edge with a customized interview process tailored to you.</p>
            </div>
          </div>
          <div className="profile-footer">
            {editProfile ? (
              <div className="content">
                <button onClick={() => setEditProfile(false)}>Cancel</button>
                <button type='submit' onClick={handleProfileSubmit}>Save</button>
              </div>
            ) : (
              <div className="content">
                <button onClick={() => setEditProfile(true)}>Edit Profile</button>
                <button onClick={() => navigate('/interview')}>Next</button>
              </div>
            )}
          </div>
          <div className="profile-form">
            <form onSubmit={handleProfileSubmit}>
              <div className="heading">Personal Information</div>
              <div className="personal-info-section">
                <div className='form-fields'>
                  <label htmlFor="firstname">First Name</label>
                  <input type='text' id='firstname' name='firstname' value={personalInfo.firstname} onChange={(e) => setPersonalInfo({ ...personalInfo, [e.target.name]: e.target.value })} disabled={!editProfile} autoComplete='off'></input>
                  <span className='form-error'>{formErrors.firstname}</span>
                </div>
                <div className='form-fields'>
                  <label htmlFor="lastname">Last Name</label>
                  <input type='text' id='lastname' name='lastname' value={personalInfo.lastname} onChange={(e) => setPersonalInfo({ ...personalInfo, [e.target.name]: e.target.value })} disabled={!editProfile} autoComplete='off'></input>
                </div>
                <div className='form-fields'>
                  <label htmlFor="email">Email</label>
                  <input type='email' id='email' name='email' value={personalInfo.email} onChange={(e) => setPersonalInfo({ ...personalInfo, [e.target.name]: e.target.value })} autoComplete='off' disabled={true}></input>
                </div>
                <div className='form-fields'>
                  <label htmlFor="gender">Gender</label>
                  <Select
                    value={selectedGender}
                    onChange={setSelectedGender}
                    options={genderOption}
                    placeholder='Gender'
                    isDisabled={!editProfile}
                  />
                </div>
              </div>
              <div className="heading">Skillset & Expertise</div>
              <div className="skills-section">
                <div className='form-fields'>
                  <label><div>Primary Skills<span className='text-danger'>*</span> </div><IoIosAddCircle onClick={() => handleIncrement('primary')} /></label>
                  {primarySkill.map((skill, index) => {
                    return <input key={index} type='text' value={skill} onChange={(e) => handlePrimarySkillChange(e, index)} placeholder='Enter your primary skills here' autoComplete='off' disabled={!editProfile}></input>
                  })}
                  <span className='form-error'>{formErrors.primarySkill}</span>
                </div>
                <div className='form-fields'>
                  <label>Secondary Skills <IoIosAddCircle onClick={() => handleIncrement('secondary')} /></label>
                  {secondarySkill.map((skill, index) => {
                    return <input key={index} type='text' value={skill} onChange={(e) => handleSecondarySkillChange(e, index)} placeholder='Enter your secondary skills here' disabled={!editProfile} autoComplete='off'></input>
                  })}
                </div>
                <div className='form-fields certication-field'>
                  <label>Certification <IoIosAddCircle onClick={() => handleIncrement('certification')} /></label>
                  {certifications.map((certificate, index) => {
                    return <input key={index} type='text' value={certificate} onChange={(e) => handleCertificationChange(e, index)} placeholder='Name of Certificate' autoComplete='off' disabled={!editProfile}></input>
                  })}
                </div>
              </div>
              <div className="heading">Education <div onClick={() => handleIncrement('education')}><IoIosAddCircle />Add Education</div></div>
              {educations.map((education, index) => {
                return <div className="education-section" key={index}>
                  <div className='form-fields'>
                    <label>Degree</label>
                    <input type='text' name='degree' value={education.degree} onChange={(e) => handleEducationChange(e, index)} placeholder='Enter your degree here' autoComplete='off' disabled={!editProfile}></input>
                  </div>
                  <div className='form-fields'>
                    <label>Year of Completion</label>
                    <Select
                      value={education.year}
                      onChange={(e) => handleEducationYearChange(e, index)}
                      options={yearOptions}
                      placeholder='YYYY'
                      name='year'
                      isDisabled={!editProfile}
                    />
                  </div>
                  <div className='form-fields'>
                    <label>University / College</label>
                    <input type='text' name='college' value={education.college} onChange={(e) => handleEducationChange(e, index)} placeholder='Enter your university here' autoComplete='off' disabled={!editProfile}></input>
                  </div>
                </div>
              })}
              <div className="heading">Work Experience <div onClick={() => handleIncrement('work')}><IoIosAddCircle />Add Work Experience</div></div>
              {works.map((work, index) => {
                return (<div className="work-section" key={index}>
                  <div className='form-fields'>
                    <label>Job Title</label>
                    <input type='text' name='title' value={work.title} onChange={(e) => handleWorkChange(e, index)} placeholder='Enter your job title' autoComplete='off' disabled={!editProfile}></input>
                  </div>
                  <div className='form-fields'>
                    <label>Company Name</label>
                    <input type='text' name='company' value={work.company} onChange={(e) => handleWorkChange(e, index)} placeholder='Enter your company name here' autoComplete='off' disabled={!editProfile}></input>
                  </div>
                  <div className='form-fields'>
                    <label>Starting from</label>
                    <div className='date-field'>
                      <Select
                        value={work.start_month}
                        onChange={(e, value) => handleWorkDateChange(e, value, index)}
                        options={monthOptions}
                        placeholder='Month'
                        name='start_month'
                        isDisabled={!editProfile}
                      />
                      <Select
                        value={work.start_year}
                        onChange={(e, value) => handleWorkDateChange(e, value, index)}
                        options={yearOptions}
                        placeholder='Year'
                        name='start_year'
                        isDisabled={!editProfile}
                      />
                    </div>
                  </div>
                  <div className='form-fields'>
                    <label htmlFor="lastname">Ending In</label>
                    <div className='date-field'>
                      <Select
                        value={work.end_month}
                        onChange={(e, value) => handleWorkDateChange(e, value, index)}
                        options={monthOptions}
                        placeholder='Month'
                        name='end_month'
                        isDisabled={!editProfile}
                      />
                      <Select
                        value={work.end_year}
                        onChange={(e, value) => handleWorkDateChange(e, value, index)}
                        options={yearOptions}
                        placeholder='Year'
                        name='end_year'
                        isDisabled={!editProfile}
                      />
                    </div>
                  </div>
                  <div className='form-fields roles-field'>
                    <label htmlFor="lastname">Roles & Responsibilities</label>
                    <textarea type='text' name='roles' value={work.roles} onChange={(e) => handleWorkChange(e, index)} placeholder=' Enter your roles & responsibilities here' rows={3} disabled={!editProfile} autoComplete='off'></textarea>
                  </div>
                </div>)
              })}
            </form>
          </div>
          <div className='next-btn'>
            {!editProfile && (
              <button onClick={() => navigate('/interview')}>Next</button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
