import React, { useEffect, useState } from "react";
import "../../scss/StartInterview.scss";
import interviewLogo from "../../assests/images/Interview.png";
import Select from "react-select";
import { FaCirclePlus } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setSelectedRounds } from "../../redux/userSlice";
import axiouus from "axios";
import { backend_url } from "../../util/config";
import axios from "../../util/api";
import toast from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import tickImg from "../../assests/images/tick.png";
import Cookies from "js-cookie";
import { IoWarningOutline } from "react-icons/io5";
import { FaCheck } from "react-icons/fa";

function StartInterview() {
  const [modalShow, setModalShow] = useState(false);
  const [modal1Show, setModal1Show] = useState(false);
  const subscriptionDetail = useSelector(
    (state) => state.user.subscriptionDetails
  );
  const requiredWorkExperienceInYrs = Array.from(
    { length: 36 },
    (_, index) => ({
      value: index,
      label: index,
    })
  );

  const requiredWorkExperienceInMonths = Array.from(
    { length: 12 },
    (_, index) => ({
      value: index,
      label: index,
    })
  );
  const seniorityLevel = [
    { value: "entry level", label: "Entry Level" },
    { value: "mid level", label: "Mid Level" },
    { value: "mid senior", label: "Mid Senior" },
    { value: "senior", label: "Senior" },
    { value: "management", label: "Management" },
    { value: "leadership", label: "Leadership" },
  ];

  const interviewerGenderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ];

  const roundDuration = [
    { value: "15", label: "15 Mins", disabled: true },
    { value: "30", label: "30 Mins" },
    { value: "45", label: "45 Mins" },
    { value: "60", label: "1 Hour" },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [planType, setplanType] = useState("");
  const user = useSelector((state) => state.user.user);
  const [primarySkills, setPrimarySkills] = useState([""]);
  const [secondarySkills, setSecondarySkills] = useState([""]);
  const [workExperienceInYears, setWorkExperienceInYears] = useState(null);
  const [workExperienceInMonths, setWorkExperienceInMonths] = useState(null);
  const [selectedSeniorityLevel, setSeniorityLevel] = useState(null);
  const [designation, setDesignation] = useState("");
  const [company, setCompany] = useState("");
  const [description, setDescription] = useState("");
  const [interviewerGender, setInterviewerGender] = useState(
    interviewerGenderOptions[0]
  );
  const [introductionRound, setIntroductionRound] = useState({
    introRound: false,
    introDuration: null,
    introLevel: "",
  });
  const [technicalRound, setTechnicalRound] = useState({
    technicalRound: false,
    technicalDuration: null,
    technicalLevel: "",
  });
  const [managerialRound, setManagerialRound] = useState({
    managerialRound: false,
    managerialDuration: null,
    managerialLevel: "",
  });
  const [hrRound, setHrRound] = useState({
    hrRound: false,
    hrDuration: null,
    hrLevel: "",
  });
  const [formErrors, setFormErrors] = useState({
    primary_Skills: "",
    require_work_experience: "",
    interview_Round: "",
    introDuration_Level: "",
    managerialDuration_Level: "",
    hrDuration_Level: "",
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isPaymentSuccess = queryParams.get("isSubscribed") === "true";

  const handlePrimarySkills = (e, index) => {
    const newPrimarySkills = [...primarySkills];
    newPrimarySkills[index] = e.target.value;
    setPrimarySkills(newPrimarySkills);
  };

  const handleSecondaySkills = (e, index) => {
    const newSecondarySkills = [...secondarySkills];
    newSecondarySkills[index] = e.target.value;
    setSecondarySkills(newSecondarySkills);
  };

  const handleIncrement = (type) => {
    if (type === "primary" && primarySkills.length < 5) {
      setPrimarySkills([...primarySkills, ""]);
    }
    if (type === "secondary" && secondarySkills.length < 5) {
      setSecondarySkills([...secondarySkills, ""]);
    }
  };

  const handleIntroduction = (e) => {
    setIntroductionRound({
      ...introductionRound,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleTechnical = (e) => {
    if (!(planType === "free" || !planType)) {
      setTechnicalRound({
        ...technicalRound,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      });
    }
  };

  const handleManagerial = (e) => {
    if (!(planType === "free" || !planType)) {
      setManagerialRound({
        ...managerialRound,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      });
    }
  };

  const handleHumanResource = (e) => {
    if (!(planType === "free" || !planType)) {
      setHrRound({
        ...hrRound,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      });
    }
  };

  const handleformErrors = () => {
    var errors = {
      primary_Skills: "",
      require_work_experience: "",
      interview_Round: "",
      introDuration_Level: "",
      technicalDuration_Level: "",
      managerialDuration_Level: "",
      hrDuration_Level: "",
    };
    var isError = false;

    if (!primarySkills[0]) {
      errors.primary_Skills = "Please enter primary skills";
      isError = true;
    }

    if (workExperienceInMonths === null || workExperienceInYears === null) {
      errors.require_work_experience =
        "Please select the required work experience";
      isError = true;
    }

    if (
      !introductionRound.introRound &&
      !technicalRound.technicalRound &&
      !managerialRound.managerialRound &&
      !hrRound.hrRound
    ) {
      errors.interview_Round = "Please select atleast one interview round";
      isError = true;
    }

    if (introductionRound.introRound) {
      if (
        introductionRound.introDuration === null ||
        introductionRound.introLevel === ""
      ) {
        errors.introDuration_Level =
          "In Introduction round both duration and difficulty should be selected";
        isError = true;
      }
    }

    if (technicalRound.technicalRound) {
      if (
        technicalRound.technicalDuration === null ||
        technicalRound.technicalLevel === ""
      ) {
        errors.technicalDuration_Level =
          "In Technical round both duration and difficulty should be selected";
        isError = true;
      }
    }

    if (managerialRound.managerialRound) {
      if (
        managerialRound.managerialDuration === null ||
        managerialRound.managerialLevel === ""
      ) {
        errors.managerialDuration_Level =
          "In Managerial/Behavioural round both duration and difficulty should be selected";
        isError = true;
      }
    }

    if (hrRound.hrRound) {
      if (hrRound.hrDuration === null || hrRound.hrLevel === "") {
        errors.hrDuration_Level =
          "In Human Resource round both duration and difficulty should be selected";
        isError = true;
      }
    }

    setFormErrors(errors);
    return isError;
  };

  const getUniqueId = () => {
    let id = localStorage.getItem("uniqueId");
    if (!id) {
      id = Cookies.get("uniqueId");
      if (!id) {
        return "";
      }
    }
    return id;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`/get_device_id?email=${user?.email}`)
      .then((res) => {
        console.log(res);
        if (res.success && res.device_id) {
          let id = getUniqueId();
          if (!res.device_id?.includes(id)) {
            setModal1Show(true);
          } else if (
            (planType === "free" || !planType) &&
            res.interview_count > 1
          ) {
            setModalShow(true);
          } else {
            if (!handleformErrors()) {
              let selectedRounds = [];
              const data = {
                Job_title: designation,
                primary_skills: JSON.stringify(primarySkills),
                years_of_experience: `${workExperienceInYears?.value}.${workExperienceInMonths?.value}`,
                duration: "",
                interview_level: "",
                interviewer_gender: interviewerGender?.value,
                company: company,
                seniority_level: selectedSeniorityLevel?.value,
                secondary_skills: JSON.stringify(secondarySkills),
                JD: description,
              };

              if (introductionRound.introRound) {
                selectedRounds.push({
                  type: "Introduction",
                  duration: introductionRound.introDuration,
                  level: introductionRound.introLevel,
                  apiEndpoint: "startIntro",
                  isFinished: false,
                  started: false,
                });
              }
              if (technicalRound.technicalRound) {
                selectedRounds.push({
                  type: "Technical",
                  duration: technicalRound.technicalDuration,
                  level: technicalRound.technicalLevel,
                  apiEndpoint: "startTechnical",
                  isFinished: false,
                  started: false,
                });
              }
              if (managerialRound.managerialRound) {
                selectedRounds.push({
                  type: "Managerial/ Behavioural",
                  duration: managerialRound.managerialDuration,
                  level: managerialRound.managerialLevel,
                  apiEndpoint: "startmanagerialRound",
                  isFinished: false,
                  started: false,
                });
              }
              if (hrRound.hrRound) {
                selectedRounds.push({
                  type: "Human Resources",
                  duration: hrRound.hrDuration,
                  level: hrRound.hrLevel,
                  apiEndpoint: "starthrRound",
                  isFinished: false,
                  started: false,
                });
              }

              if (selectedRounds.length === 1) {
                const round = selectedRounds[0];
                if (round) {
                  data.duration = round.duration;
                  data.interview_level = round.level;
                }
              } else if (selectedRounds.length > 1) {
                data.duration = selectedRounds[0].duration;
                data.interview_level = selectedRounds[0].level;
              }

              let payLoad = JSON.stringify({
                selectedRounds,
                user_id: user?.user_id,
              });
              data.round_selected = selectedRounds.length;
              const config = {
                method: "post",
                url: `${backend_url}/selected-rounds`,
                headers: {
                  "Content-Type": "application/json",
                },
                data: payLoad,
              };
              dispatch(setLoading(true));
              axiouus
                .request(config)
                .then((res) => {
                  dispatch(setLoading(false));
                  if (res.data.success) {
                    sessionStorage.setItem(
                      "selectedRounds",
                      JSON.stringify(selectedRounds)
                    );
                    dispatch(setSelectedRounds(selectedRounds));
                    sessionStorage.setItem("data", JSON.stringify(data));
                    navigate("/interviewProcess");
                  }
                })
                .catch((error) => {
                  dispatch(setLoading(false));
                  console.log("error", error);
                });

              const interviewDetails = {
                user_id: user?.user_id,
                primary_skills: JSON.stringify(primarySkills),
                secondary_skills: JSON.stringify(secondarySkills),
                required_experience_years: workExperienceInYears?.value,
                required_experience_months: workExperienceInMonths?.value,
                seniority_level: selectedSeniorityLevel?.value,
                designation: designation,
                company: company,
                jd: description,
                interviewer_gender: interviewerGender?.value,
              };

              axios
                .post("/save_interview_preferences", interviewDetails)
                .then((res) => {
                  console.log(res);
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  };

  useEffect(() => {
    dispatch(setLoading(true));
    axios
      .post("/get_interview_preferences", { user_id: user?.user_id })
      .then((res) => {
        dispatch(setLoading(false));
        if (res.success) {
          if (res.data && res.data[0]) {
            setCompany(res.data[0]?.company);
            setDesignation(res.data[0]?.designation);
            setWorkExperienceInMonths({
              value: res.data[0]?.required_experience_months,
              label: res.data[0]?.required_experience_months,
            });
            setWorkExperienceInYears({
              value: res.data[0]?.required_experience_years,
              label: res.data[0]?.required_experience_years,
            });
            setSeniorityLevel({
              label: res.data[0]?.seniority_level,
              value: res.data[0]?.seniority_level,
            });
            setDescription(res.data[0]?.jd);
            setPrimarySkills(JSON.parse(res.data[0]?.primary_skills) || []);
            setSecondarySkills(JSON.parse(res.data[0]?.secondary_skills) || []);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(setLoading(false));
      });
  }, []);

  useEffect(() => {
    if (isPaymentSuccess) {
      const savedSubscription = JSON.parse(
        localStorage.getItem("activeSubscription")
      );
      if (
        savedSubscription &&
        savedSubscription?.orderReferenceId &&
        savedSubscription?.planPrice
      ) {
        window.dataLayer.push({
          event: "awinPurchase",
          orderDetail: {
            orderReferenceId: savedSubscription?.orderReferenceId,
            planPrice: savedSubscription?.planPrice,
          },
        });
      }
    }
  }, [isPaymentSuccess]);

  useEffect(() => {
    if (subscriptionDetail) {
      setplanType(subscriptionDetail?.planType);
    }
  }, [subscriptionDetail]);

  const showToast = (e) => {
    if (planType === "free" || !planType) {
      if (e.target.type === "checkbox" || e.target.type === "radio") {
        e.preventDefault();
        e.stopPropagation();
      }
      setModalShow(true);
    }
  };

  return (
    <div className="start-interview-wrapper">
      <div className="start-interview-header">
        <div className="heading-with-logo">
          <img src={interviewLogo} alt="logo"></img>
          <h3>Start Interview</h3>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="start-interview-body">
          <div className="information-about-the-job">
            <h6>Information about the job</h6>
            <div className="row-data row1">
              <div className="primary-skills">
                <label htmlFor="primarySkills">
                  <span className="label">
                    Primary Skills<span style={{ color: "#F5222D" }}>*</span>
                  </span>{" "}
                  <FaCirclePlus
                    color="#104CBA"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleIncrement("primary")}
                  />
                </label>
                {primarySkills.map((skill, index) => {
                  return (
                    <input
                      type="text"
                      key={index}
                      id="primarySkills"
                      placeholder="Select your primary skills"
                      name="primarySkills"
                      value={skill}
                      onChange={(e) => handlePrimarySkills(e, index)}
                    ></input>
                  );
                })}
                <span className="form-errors">{formErrors.primary_Skills}</span>
              </div>
              <div className="secondary-skills">
                <label htmlFor="secondarySkills">
                  <div>
                    Secondary Skills<span>(Optional)</span>
                  </div>{" "}
                  <FaCirclePlus
                    color="#104CBA"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleIncrement("secondary")}
                  />
                </label>
                {secondarySkills.map((skill, index) => {
                  return (
                    <input
                      type="text"
                      key={index}
                      id="secondarySkills"
                      placeholder="Select your secondary skills here"
                      name="secondarySkills"
                      value={skill}
                      onChange={(e) => handleSecondaySkills(e, index)}
                    ></input>
                  );
                })}
              </div>
            </div>
            <div className="row-data row2">
              <div className="left-section">
                <div className="required-work-experience">
                  <label htmlFor="required-work-experience">
                    <span>
                      Required Work Experience
                      <span style={{ color: "#F5222D" }}>*</span>
                    </span>
                  </label>
                  <div className="select-box">
                    <Select
                      value={workExperienceInYears}
                      onChange={setWorkExperienceInYears}
                      options={requiredWorkExperienceInYrs}
                      placeholder="Years"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          borderRadius: "10px",
                          padding: "4px 0",
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          fontSize: "14px",
                        }),
                      }}
                    />
                    <Select
                      value={workExperienceInMonths}
                      onChange={setWorkExperienceInMonths}
                      options={requiredWorkExperienceInMonths}
                      placeholder="Months"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          borderRadius: "10px",
                          padding: "4px 0",
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          fontSize: "14px",
                        }),
                      }}
                    />
                  </div>
                  <span className="form-errors">
                    {formErrors.require_work_experience}
                  </span>
                </div>
                <div className="seniority-level">
                  <label htmlFor="seniorityLevel">Seniority Level</label>
                  <Select
                    value={selectedSeniorityLevel}
                    onChange={setSeniorityLevel}
                    options={seniorityLevel}
                    placeholder="Select Seniority level"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderRadius: "10px",
                        padding: "4px 0",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: "14px",
                      }),
                    }}
                  />
                </div>
              </div>
              <div className="right-section">
                <div className="designation">
                  <label htmlFor="designation">
                    Designation<span>(Optional)</span>
                  </label>
                  <input
                    type="text"
                    id="designation"
                    value={designation}
                    placeholder="Enter your designation here"
                    onChange={(e) => setDesignation(e.target.value)}
                  ></input>
                </div>
                <div className="company">
                  <label htmlFor="company">
                    Company<span>(Optional)</span>
                  </label>
                  <input
                    type="text"
                    id="company"
                    value={company}
                    placeholder="Enter your company name here"
                    onChange={(e) => setCompany(e.target.value)}
                  ></input>
                </div>
              </div>
            </div>
          </div>
          <div className="jd-description-section">
            <h6>
              Paste the JD description below<span>(Optional)</span>
            </h6>
            <textarea
              value={description}
              className="jd-text"
              placeholder="Enter a description..."
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>
          <div className="personalize-your-interview">
            <div className="first-section">
              <div className="heading-with-description">
                <h6>Personalize your interview</h6>
                <p>
                  Choose the type of interview round based on what your goals
                  are
                </p>
              </div>
              <div className="select-box gender-select">
                <label htmlFor="gender">Select Interviewer's Gender</label>
                <Select
                  defaultValue={interviewerGenderOptions[0]}
                  onChange={setInterviewerGender}
                  options={interviewerGenderOptions}
                  placeholder="Select Interviewer's Gender"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: "10px",
                      padding: "4px 0",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      fontSize: "14px",
                    }),
                  }}
                  id="gender"
                />
              </div>
            </div>
            <div className="second-section">
              <div className="section-heading">
                <span>Interview Round</span>
                <span>Duration</span>
                <span>Difficulty</span>
              </div>
              <div className="form-section">
                <div className="introduction-section">
                  <div className="checkbox">
                    <input
                      type="checkbox"
                      name="introRound"
                      id="Intro"
                      onChange={handleIntroduction}
                    ></input>
                    <label htmlFor="Intro">Introduction</label>
                  </div>
                  <div className="duration">
                    <Select
                      defaultValue={introductionRound.introDuration}
                      onChange={(e) =>
                        setIntroductionRound({
                          ...introductionRound,
                          introDuration: e.value,
                        })
                      }
                      options={roundDuration}
                      placeholder="Select the time"
                      isOptionDisabled={(option) =>
                        (planType === "free" || !planType) &&
                        option.value !== "15"
                      }
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          borderRadius: "10px",
                          padding: "4px 0",
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          fontSize: "14px",
                        }),
                      }}
                      name="introDuration"
                    />
                  </div>
                  <div className="difficulty-level">
                    <div>
                      <input
                        type="radio"
                        name="introLevel"
                        value="beginner"
                        id="beginner"
                        onChange={handleIntroduction}
                      ></input>
                      <label htmlFor="beginner">Beginner</label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        name="introLevel"
                        value="intermediate"
                        id="intermediate"
                        onChange={handleIntroduction}
                      ></input>
                      <label htmlFor="intermediate">Intermediate</label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        name="introLevel"
                        id="advance"
                        value="advance"
                        onChange={handleIntroduction}
                      ></input>
                      <label htmlFor="advance">Advance</label>
                    </div>
                  </div>
                </div>
                {
                  <>
                    <div className="technical-section">
                      <div className="checkbox">
                        <input
                          onClick={showToast}
                          type="checkbox"
                          name="technicalRound"
                          onChange={handleTechnical}
                          id="tech"
                          className={
                            planType === "free" || !planType
                              ? "freeTrialActive"
                              : ""
                          }
                        ></input>
                        <label
                          className={
                            planType === "free" || !planType
                              ? "freeTrialActive"
                              : ""
                          }
                          htmlFor="tech"
                          onClick={showToast}
                        >
                          Technical
                        </label>
                      </div>
                      <div className="duration" onClick={showToast}>
                        <Select
                          defaultValue={technicalRound.technicalDuration}
                          onChange={(e) =>
                            setTechnicalRound({
                              ...technicalRound,
                              technicalDuration: e.value,
                            })
                          }
                          options={roundDuration}
                          placeholder="Select the time"
                          isDisabled={planType === "free" || !planType}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "10px",
                              padding: "4px 0",
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              fontSize: "14px",
                            }),
                          }}
                          name="technicalDuration"
                        />
                      </div>
                      <div className="difficulty-level">
                        <div>
                          <input
                            onClick={showToast}
                            type="radio"
                            name="technicalLevel"
                            id="tech-beginner"
                            value="beginner"
                            onChange={handleTechnical}
                            className={
                              planType === "free" || !planType
                                ? "freeTrialActive"
                                : ""
                            }
                          ></input>
                          <label
                            className={
                              planType === "free" || !planType
                                ? "freeTrialActive"
                                : ""
                            }
                            onClick={showToast}
                            htmlFor="tech-beginner"
                          >
                            Beginner
                          </label>
                        </div>
                        <div>
                          <input
                            onClick={showToast}
                            type="radio"
                            name="technicalLevel"
                            id="tech-intermediate"
                            value="intermediate"
                            onChange={handleTechnical}
                            className={
                              planType === "free" || !planType
                                ? "freeTrialActive"
                                : ""
                            }
                          ></input>
                          <label
                            className={
                              planType === "free" || !planType
                                ? "freeTrialActive"
                                : ""
                            }
                            onClick={showToast}
                            htmlFor="tech-intermediate"
                          >
                            Intermediate
                          </label>
                        </div>
                        <div>
                          <input
                            onClick={showToast}
                            type="radio"
                            name="technicalLevel"
                            id="tech-advance"
                            value="advance"
                            onChange={handleTechnical}
                            className={
                              planType === "free" || !planType
                                ? "freeTrialActive"
                                : ""
                            }
                          ></input>
                          <label
                            className={
                              planType === "free" || !planType
                                ? "freeTrialActive"
                                : ""
                            }
                            onClick={showToast}
                            htmlFor="tech-advance"
                          >
                            Advance
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="managerial-section">
                      <div className="checkbox">
                        <input
                          onClick={showToast}
                          type="checkbox"
                          name="managerialRound"
                          id="managerial"
                          onChange={handleManagerial}
                          className={
                            planType === "free" || !planType
                              ? "freeTrialActive"
                              : ""
                          }
                        ></input>
                        <label
                          className={
                            planType === "free" || !planType
                              ? "freeTrialActive"
                              : ""
                          }
                          htmlFor="managerial"
                          onClick={showToast}
                        >
                          Managerial/ Behavorial
                        </label>
                      </div>
                      <div className="duration" onClick={showToast}>
                        <Select
                          defaultValue={managerialRound.managerialDuration}
                          onChange={(e) => {
                            setManagerialRound({
                              ...managerialRound,
                              managerialDuration: e.value,
                            });
                          }}
                          options={roundDuration}
                          placeholder="Select the time"
                          isDisabled={planType === "free" || !planType}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "10px",
                              padding: "4px 0",
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              fontSize: "14px",
                            }),
                          }}
                        />
                      </div>
                      <div className="difficulty-level">
                        <div>
                          <input
                            onClick={showToast}
                            type="radio"
                            name="managerialLevel"
                            id="managerial-beginner"
                            value="beginner"
                            onChange={handleManagerial}
                            className={
                              planType === "free" || !planType
                                ? "freeTrialActive"
                                : ""
                            }
                          ></input>
                          <label
                            className={
                              planType === "free" || !planType
                                ? "freeTrialActive"
                                : ""
                            }
                            onClick={showToast}
                            htmlFor="managerial-beginner"
                          >
                            Beginner
                          </label>
                        </div>
                        <div>
                          <input
                            onClick={showToast}
                            type="radio"
                            name="managerialLevel"
                            id="managerial-intermediate"
                            value="intermediate"
                            onChange={handleManagerial}
                            className={
                              planType === "free" || !planType
                                ? "freeTrialActive"
                                : ""
                            }
                          ></input>
                          <label
                            className={
                              planType === "free" || !planType
                                ? "freeTrialActive"
                                : ""
                            }
                            onClick={showToast}
                            htmlFor="managerial-intermediate"
                          >
                            Intermediate
                          </label>
                        </div>
                        <div>
                          <input
                            onClick={showToast}
                            type="radio"
                            name="managerialLevel"
                            id="managerial-advance"
                            value="advance"
                            onChange={handleManagerial}
                            className={
                              planType === "free" || !planType
                                ? "freeTrialActive"
                                : ""
                            }
                          ></input>
                          <label
                            className={
                              planType === "free" || !planType
                                ? "freeTrialActive"
                                : ""
                            }
                            onClick={showToast}
                            htmlFor="managerial-advance"
                          >
                            Advance
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="hr-section">
                      <div className="checkbox">
                        <input
                          onClick={showToast}
                          type="checkbox"
                          name="hrRound"
                          id="hr"
                          onChange={handleHumanResource}
                          className={
                            planType === "free" || !planType
                              ? "freeTrialActive"
                              : ""
                          }
                        ></input>
                        <label
                          className={
                            planType === "free" || !planType
                              ? "freeTrialActive"
                              : ""
                          }
                          htmlFor="hr"
                          onClick={showToast}
                        >
                          Human Resource
                        </label>
                      </div>
                      <div className="duration" onClick={showToast}>
                        <Select
                          defaultValue={hrRound.hrDuration}
                          onChange={(e) =>
                            setHrRound({ ...hrRound, hrDuration: e.value })
                          }
                          options={roundDuration}
                          placeholder="Select the time"
                          isDisabled={planType === "free" || !planType}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "10px",
                              padding: "4px 0",
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              fontSize: "14px",
                            }),
                          }}
                        />
                      </div>
                      <div className="difficulty-level">
                        <div>
                          <input
                            onClick={showToast}
                            type="radio"
                            name="hrLevel"
                            id="hr-beginner"
                            value="beginner"
                            onChange={handleHumanResource}
                            className={
                              planType === "free" || !planType
                                ? "freeTrialActive"
                                : ""
                            }
                          ></input>
                          <label
                            className={
                              planType === "free" || !planType
                                ? "freeTrialActive"
                                : ""
                            }
                            onClick={showToast}
                            htmlFor="hr-beginner"
                          >
                            Beginner
                          </label>
                        </div>
                        <div>
                          <input
                            onClick={showToast}
                            type="radio"
                            name="hrLevel"
                            id="hr-intermediate"
                            value="intermediate"
                            onChange={handleHumanResource}
                            className={
                              planType === "free" || !planType
                                ? "freeTrialActive"
                                : ""
                            }
                          ></input>
                          <label
                            className={
                              planType === "free" || !planType
                                ? "freeTrialActive"
                                : ""
                            }
                            onClick={showToast}
                            htmlFor="hr-intermediate"
                          >
                            Intermediate
                          </label>
                        </div>
                        <div>
                          <input
                            onClick={showToast}
                            type="radio"
                            name="hrLevel"
                            id="hr-advance"
                            value="advance"
                            onChange={handleHumanResource}
                            className={
                              planType === "free" || !planType
                                ? "freeTrialActive"
                                : ""
                            }
                          ></input>
                          <label
                            className={
                              planType === "free" || !planType
                                ? "freeTrialActive"
                                : ""
                            }
                            onClick={showToast}
                            htmlFor="hr-advance"
                          >
                            Advance
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="start-interview-button">
          <div>
            <p className="form-errors mb-2">
              {formErrors.interview_Round}
              {formErrors.introDuration_Level}
            </p>
            <p className="form-errors mb-2">
              {formErrors.technicalDuration_Level}
            </p>
            <p className="form-errors mb-2">
              {formErrors.managerialDuration_Level}
            </p>
            <p className="form-errors mb-2">{formErrors.hrDuration_Level}</p>
            <p className="form-errors mb-2">{formErrors.primary_Skills}</p>
            <p className="form-errors mb-2">
              {formErrors.require_work_experience}
            </p>
          </div>
          <button type="submit">Start Interview</button>
        </div>
      </form>
      <Modal
        className="end-Interview-modal"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Body className="p-4">
          <div className="plan-upgrade d-flex flex-column gap-4">
            <h2 className="text-center fw-bold">
              Take your interview practice to next level
            </h2>
            <p className="text-center">
              In the free trial plan, you only have access to the Introduction
              round up to 2 times. To access all the rounds for unlimited times,
              upgrade your plan.
            </p>
            <div className="upgraded-plan-features d-flex justify-content-around">
              <div className="left">
                <div className="card-details d-flex flex-column gap-2">
                  <div className="plan-property d-flex align-items-center gap-2">
                    <FaCheck color="green" />
                    <p>All Rounds Access</p>
                  </div>
                  <div className="plan-property d-flex align-items-center gap-2">
                    <FaCheck color="green" />
                    <p>Sync up to 2 devices</p>
                  </div>
                  <div className="plan-property d-flex align-items-center gap-2">
                    <FaCheck color="green" />
                    <p>Unlimited interviews</p>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="card-details d-flex flex-column gap-2">
                  <div className="plan-property d-flex align-items-center gap-2">
                    <FaCheck color="green" />
                    <p>Real-time feedback</p>
                  </div>
                  <div className="plan-property d-flex align-items-center gap-2">
                    <FaCheck color="green" />
                    <p>Suggested answers</p>
                  </div>
                  <div className="plan-property d-flex align-items-center gap-2">
                    <FaCheck color="green" />
                    <p>Overall score</p>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="btn btn-primary"
              onClick={() => navigate("/subscription")}
            >
              Upgrade
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="device-limit-reached"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modal1Show}
        onHide={() => setModal1Show(false)}
      >
        <Modal.Body className="p-4">
          <div className="plan-upgrade d-flex flex-column gap-4 align-items-center">
            <h2 className="text-center fw-bold">Oops! Device Limit Reached</h2>
            <IoWarningOutline size={50} />
            <p className="text-center fs-6">
              You have reached the device limit. You cannot log in on more than
              2 devices.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default StartInterview;
