import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import NavBar from '../NavBar/NavBar';
import Footer from '../../components/Footer';
import blogPageImg from '../../assests/images/blogImg1.png';
import '../../scss/Blogs.scss';
import blogImg1 from '../../assests/images/blogImg1.png';
import blogImg2 from '../../assests/images/blogImg2.png';
import BlogCard from '../../components/BlogCard';
import { useDispatch } from 'react-redux';
import axios from '../../util/api';
import { setLoading } from '../../redux/userSlice';

function BlogPage() {
  const { blogId } = useParams();
  const dispatch = useDispatch();
  const [blogData, setblogData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0)

    if (blogId) {
      dispatch(setLoading(true));
      axios.post(`/get_blog/${blogId}`).then(res => {
        dispatch(setLoading(false));
        if (res.success) {
          setblogData(res.blog)
        }
      }).catch(error => {
        dispatch(setLoading(false));
        console.log(error);
      })
    }
  }, [])

  return (
    <div className='blogpage-wrapper'>
      <NavBar />
      <div className='blogpage-content'>
        <img src={`data:image/png;base64,${blogData?.image}`} alt='Img'></img>
        <div className='blogpage-description'>
          {/* <span className='read-time'>Lorem Ipsum | 5 Min Read</span> */}
          <h2>{blogData?.title}</h2>
          <div className='description'>
            {blogData?.description}
          </div>
        </div>
        {/* <div className='follow-btn-section'>
            <button className='follow-btn'>Follow For More</button>
          </div> */}
        {/* <div className='more-blog-section'>
            <div className='more-blog-posts'>
              <h2>More Blog Posts</h2>
              <div className='cards'>
                {
                  cardData.map((data, index)=> {
                    return <BlogCard key={index} imgurl = {data.image} heading= {data.cardHeading} description = {data.cardDescription} blogId= {data.blogId} />
                  })
                }
              </div>
            </div>
          </div> */}
      </div>
      <Footer />
    </div>
  )
}

export default BlogPage